import React from 'react';
import { number, shape, string } from 'prop-types';

export function Icon({ name, fill, dimensions }) {
  switch (name) {
    case 'arrow-up':
      return (
        <svg
          {...dimensions}
          viewBox="0.6 0.4 7.5 9.2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g {...{ fill }}>
            <path d="M4.8158.5747l3.0756 3.4065c.2735.2418.2693.56.0156.77-.243.2874-.5683.2874-.8054.007L4.9388 2.384v6.6785c0 .3464-.216.5625-.5625.5625-.1456 0-.277-.0564-.382-.161-.1066-.107-.161-.2457-.161-.4015V2.384L1.6765 4.7512c-.243.2873-.5683.2873-.782.0294-.2873-.243-.2873-.5683-.0236-.7886L3.9563.5938l.185.168h-.25V.4922h.25c-.0303 0-.0595.0062-.0864.0175-.029.0122-.0556.0304-.0775.053-.014.0142-.0262.0303-.0362.0477-.019.0335-.03.0718-.03.1123l.073-.1768c.021-.021.0442-.0396.0696-.054.0124-.007.0254-.0137.039-.019.033-.013.0686-.02.1072-.02v.25l-.15-.2c.024-.0177.0333-.024.0427-.03.0666-.0428.1433-.0746.2637-.098h.039c.1434 0 .2724.0512.372.151l.0197.0195.0732.1767c0-.0594-.0238-.1143-.062-.1557C4.752.5195 4.6936.4922 4.63.4922l.1856.0825z" />
          </g>
        </svg>
      );
    case 'arrow-down':
      return (
        <svg
          {...dimensions}
          viewBox="0.6 0.7 7.5 9.3"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g {...{ fill }}>
            <path d="M7.9727 6.3628l-3.086 3.418-.1072.0477.0213.032c-.1154.0768-.2233.12-.334.12L4.367 9.973c-.086-.0215-.168-.0625-.2073-.097l-.104-.0664L4.036 9.79l-.0144-.035-3.056-3.369C.678 6.1432.678 5.818.95 5.5888c.2408-.2408.5424-.2408.7913.0085L3.904 7.9715v-6.659c0-.1558.0544-.2946.161-.4014.105-.1044.2365-.161.382-.161.3465 0 .5626.216.5626.5627v6.659l2.171-2.3826c.2384-.2382.5362-.2407.776-.0072.2793.2423.277.5636.016.7813z" />
          </g>
        </svg>
      );
    case 'equal':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 18 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.2002 5.99995H16.8002M1.2002 1.19995H16.8002"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'cash':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6998 7.80005H16.1997M4.19995 13.8001H5.6998M20.9998 12.6H22.1998V1.80005H5.3998V3.00005M1.7998 5.40005H18.5998V16.2H1.7998V5.40005ZM12.2998 10.8002C12.2998 11.96 11.3596 12.9002 10.1998 12.9002C9.04001 12.9002 8.0998 11.96 8.0998 10.8002C8.0998 9.6404 9.04001 8.7002 10.1998 8.7002C11.3596 8.7002 12.2998 9.6404 12.2998 10.8002Z"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'question':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.9603 9.31989C8.9603 8.11987 10.6783 6.73919 12.2005 7.87996C13.1669 8.60429 13.1605 10.2799 11.9605 11C10.7605 11.72 10.6405 12.32 10.6405 13.28M20.6004 10.9999C20.6004 16.3018 16.3023 20.5999 11.0004 20.5999C5.69846 20.5999 1.40039 16.3018 1.40039 10.9999C1.40039 5.69797 5.69846 1.3999 11.0004 1.3999C16.3023 1.3999 20.6004 5.69797 20.6004 10.9999Z"
            stroke="#BCBCBC"
            strokeWidth="2"
          />
          <path
            d="M10.6406 14.8396H10.6526"
            stroke="#BCBCBC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'work-order':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 24 24"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.04737 9.15479H13.9256C14.4036 9.15479 14.7926 8.76595 14.7926 8.28794C14.7926 7.80992 14.4036 7.42109 13.9256 7.42109H5.04737C4.56921 7.42109 4.18023 7.80992 4.18023 8.28794C4.18023 8.76595 4.56921 9.15479 5.04737 9.15479Z"
            fill="#014398"
          />
          <path
            d="M11.1957 14.8421H5.04754C4.56938 14.8421 4.18039 15.231 4.18039 15.709C4.18039 16.187 4.56938 16.5758 5.04754 16.5758H11.1957C11.427 16.5758 11.6447 16.4858 11.8087 16.322C11.9725 16.1583 12.0627 15.9405 12.0627 15.709C12.0627 15.4805 11.97 15.2571 11.8087 15.0961C11.6449 14.9323 11.4272 14.8421 11.1957 14.8421Z"
            fill="#014398"
          />
          <path
            d="M5.04737 5.44427H13.9256C14.4036 5.44427 14.7926 5.05543 14.7926 4.57742C14.7926 4.09941 14.4036 3.71035 13.9256 3.71035H5.04737C4.56921 3.71035 4.18023 4.0993 4.18023 4.57742C4.18023 5.05554 4.56921 5.44427 5.04737 5.44427Z"
            fill="#014398"
          />
          <path
            d="M23.9954 17.9683C23.9402 16.5806 23.3879 15.2572 22.4402 14.242C21.5434 13.281 20.3472 12.6542 19.0515 12.4618V3.34062C19.0515 2.44831 18.704 1.60941 18.0729 0.978442C17.4419 0.347479 16.603 0 15.7106 0H3.34091C2.44875 0 1.60982 0.34748 0.978474 0.978498C0.34751 1.60963 0 2.44854 0 3.34062V16.946C0 17.8381 0.34751 18.677 0.978529 19.3082C1.60977 19.9392 2.44875 20.2867 3.34091 20.2867H12.7861C13.2635 21.5241 14.1524 22.5635 15.3049 23.2269C16.1903 23.7364 17.1897 24 18.1993 24C18.5621 24 18.9264 23.9659 19.287 23.897C20.6512 23.6367 21.8781 22.8943 22.7418 21.8068C23.6056 20.7191 24.0508 19.3558 23.9954 17.9683ZM12.3699 18.1937C12.3752 18.3131 12.3844 18.4332 12.3973 18.5529H3.34091C2.91168 18.5529 2.50811 18.3858 2.20467 18.0824C1.90117 17.7789 1.73407 17.3754 1.73407 16.9462V3.34073C1.73407 2.91153 1.90117 2.508 2.20467 2.20459C2.50811 1.90112 2.91168 1.73403 3.34091 1.73403H15.7106C16.1398 1.73403 16.5433 1.90112 16.8469 2.20464C17.1504 2.508 17.3176 2.91147 17.3176 3.34073V12.4617C15.9841 12.6599 14.7623 13.3148 13.8572 14.3213C12.9025 15.3832 12.3742 16.7543 12.3697 18.182L12.3699 18.1937ZM22.2651 18.183C22.2651 19.2729 21.8406 20.2977 21.0699 21.0684C20.2992 21.839 19.2744 22.2634 18.1842 22.2634C17.094 22.2634 16.0693 21.839 15.2985 21.0684C14.5278 20.2977 14.1033 19.273 14.1033 18.183C14.1033 17.0929 14.5278 16.0682 15.2985 15.2975C16.0692 14.5269 17.0941 14.1025 18.1842 14.1025C19.2744 14.1025 20.2992 14.5269 21.0699 15.2975C21.8406 16.0682 22.2651 17.0929 22.2651 18.183Z"
            fill="#014398"
          />
          <path
            d="M18.1843 14.8423C17.7063 14.8423 17.3174 15.2311 17.3174 15.7091V17.3161H16.392C15.914 17.3161 15.525 17.7049 15.525 18.1829C15.525 18.6609 15.914 19.0498 16.392 19.0498H18.1843C18.4127 19.0498 18.6362 18.9571 18.7971 18.7959C18.9586 18.6347 19.0513 18.4113 19.0513 18.1828V15.7091C19.0513 15.2311 18.6623 14.8423 18.1843 14.8423Z"
            fill="#014398"
          />
          <path
            d="M14.4094 11.9985C14.4094 11.5205 14.0205 11.1316 13.5425 11.1316H5.04776C4.5696 11.1316 4.18061 11.5204 4.18061 11.9985C4.18061 12.4765 4.5696 12.8653 5.04776 12.8653H13.5425C14.0205 12.8653 14.4094 12.4765 14.4094 11.9985Z"
            fill="#014398"
          />
        </svg>
      );
    case 'clock':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.50012 5.49988V10.4999H11.5001M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
            stroke="#D19C19"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'history':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 15.2454C7.3696 16.3433 9.10811 17 11 17C15.4183 17 19 13.4183 19 9C19 4.58172 15.4183 1 11 1C6.58172 1 3 4.58172 3 9V10.5M3 10.5L1 8.59773M3 10.5L5 8.5M10.5001 5.49988V10.4999H13.5001"
            stroke="#5E6271"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'white-star':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 1.76001L14.0037 7.84325L20.72 8.81874L15.86 13.5539L17.0073 20.24L11 17.0832L4.99274 20.24L6.14003 13.5539L1.28003 8.81874L7.99638 7.84325L11 1.76001Z"
            stroke="#014398"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'opened':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.95664 4.59602H5.16016V22.2003H18.8402V4.59602H16.0437V3.56047M7.95664 4.59602V3.56047L9.92656 3.56072C9.92656 2.41689 10.8549 1.80029 12.0002 1.80029C13.1454 1.80029 14.0738 2.41689 14.0738 3.56072L16.0437 3.56047M7.95664 4.59602V6.14934H16.0437V3.56047M9.6001 14.1596H12.0001M12.0001 14.1596H14.4001M12.0001 14.1596V11.7596M12.0001 14.1596V16.5596M11.9881 4.0207H12.0001"
            stroke="#014398"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'completed':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.95664 4.59602H5.16016V22.2003H18.8402V4.59602H16.0437V3.56047M7.95664 4.59602V3.56047L9.92656 3.56072C9.92656 2.41689 10.8549 1.80029 12.0002 1.80029C13.1454 1.80029 14.0738 2.41689 14.0738 3.56072L16.0437 3.56047M7.95664 4.59602V6.14934H16.0437V3.56047M9.59995 14.3601L11.2 15.9601L14.4 12.3601M11.9881 4.0207H12.0001"
            stroke="#014398"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'close':
      return (
        <svg
          {...dimensions}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 1.5L10.5 10.5M10.5 1.5L1.5 10.5"
            stroke="#BCBCBC"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'list':
      return (
        <svg
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.666992 0.791748C0.666992 0.44657 0.946814 0.166748 1.29199 0.166748H14.2087C14.5538 0.166748 14.8337 0.44657 14.8337 0.791748C14.8337 1.13693 14.5538 1.41675 14.2087 1.41675H1.29199C0.946814 1.41675 0.666992 1.13693 0.666992 0.791748ZM0.666992 10.7917C0.666992 10.4466 0.946814 10.1667 1.29199 10.1667H11.7087C12.0538 10.1667 12.3337 10.4466 12.3337 10.7917C12.3337 11.1369 12.0538 11.4167 11.7087 11.4167H1.29199C0.946814 11.4167 0.666992 11.1369 0.666992 10.7917ZM1.29199 5.16675C0.946814 5.16675 0.666992 5.44657 0.666992 5.79175C0.666992 6.13693 0.946814 6.41675 1.29199 6.41675H16.7087C17.0538 6.41675 17.3337 6.13693 17.3337 5.79175C17.3337 5.44657 17.0538 5.16675 16.7087 5.16675H1.29199Z"
            fill={fill}
          />
        </svg>
      );

    case 'gallery':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.29167 8.83333C6.3272 8.83333 7.16667 9.6728 7.16667 10.7083V13.625C7.16667 14.6605 6.3272 15.5 5.29167 15.5H2.375C1.33947 15.5 0.5 14.6605 0.5 13.625V10.7083C0.5 9.6728 1.33947 8.83333 2.375 8.83333H5.29167ZM13.625 8.83333C14.6605 8.83333 15.5 9.6728 15.5 10.7083V13.625C15.5 14.6605 14.6605 15.5 13.625 15.5H10.7083C9.6728 15.5 8.83333 14.6605 8.83333 13.625V10.7083C8.83333 9.6728 9.6728 8.83333 10.7083 8.83333H13.625ZM5.29167 0.5C6.3272 0.5 7.16667 1.33947 7.16667 2.375V5.29167C7.16667 6.3272 6.3272 7.16667 5.29167 7.16667H2.375C1.33947 7.16667 0.5 6.3272 0.5 5.29167V2.375C0.5 1.33947 1.33947 0.5 2.375 0.5H5.29167ZM13.625 0.5C14.6605 0.5 15.5 1.33947 15.5 2.375V5.29167C15.5 6.3272 14.6605 7.16667 13.625 7.16667H10.7083C9.6728 7.16667 8.83333 6.3272 8.83333 5.29167V2.375C8.83333 1.33947 9.6728 0.5 10.7083 0.5H13.625Z"
            fill={fill}
          />
        </svg>
      );

    default:
      return null;
  }
}

Icon.propTypes = {
  name: string.isRequired,
  fill: string,
  dimensions: shape({
    width: number,
    height: number,
  }),
};
