import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { getProjectSpendTypeOrNothingUI } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import { Icon } from '@poly/site-book';
import {
  getProjectSpendCostByConfig,
  convertCentsToDollars,
  isNilOrEmpty,
  formatTotal,
  formatDate,
  assocBy,
} from '@poly/utils';

import { spendReportCellType } from './constants.js';
import { ProjectLink } from '../../components/ProjectLink.js';

function InvoiceNumberLink({ file, invoiceNumber }) {
  return file ? (
    <a href={file} target="_blank" rel="noopener noreferrer">
      {invoiceNumber}
    </a>
  ) : (
    invoiceNumber
  );
}

InvoiceNumberLink.propTypes = {
  file: string,
  invoiceNumber: string.isRequired,
};
const SpendReportProjectLinkWrapperS = styled.div`
  gap: 5px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const WarningIconWrapperS = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`;

const WarningTooltipS = styled.div`
  top: 23px;
  z-index: 1;
  left: -43px;
  width: 270px;
  display: flex;
  color: white;
  font-size: 14px;
  position: absolute;
  padding: 12px 20px;
  border-radius: 5px;
  background-color: #12347a;

  ::after {
    width: 0;
    height: 0;
    top: -2px;
    left: 50px;
    content: '';
    display: block;
    box-shadow: none;
    position: absolute;
    margin-left: -0.5em;
    box-sizing: border-box;
    transform: rotate(135deg);
    border: 5px solid #12347a;
    border-color: transparent transparent #12347a #12347a;
  }
`;

function SpendReportProjectLink({ projectNumber, exemptSalesTax }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <SpendReportProjectLinkWrapperS>
      <ProjectLink projectId={projectNumber} target="_blank" />
      {exemptSalesTax && (
        <WarningIconWrapperS
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Icon name="warning" fill="#f79009" dimensions={{ height: 12 }} />
          {isHovered && (
            <WarningTooltipS>
              This project is exempt from sales tax
            </WarningTooltipS>
          )}
        </WarningIconWrapperS>
      )}
    </SpendReportProjectLinkWrapperS>
  );
}

SpendReportProjectLink.propTypes = {
  exemptSalesTax: bool,
  projectNumber: string.isRequired,
};

const getTableConfig = (isTransparent, isPrint) => [
  [
    { title: 'Project #', type: spendReportCellType.default },
    ({ projectNumber, exemptSalesTax }) =>
      isPrint ? (
        projectNumber
      ) : (
        <SpendReportProjectLink {...{ projectNumber, exemptSalesTax }} />
      ),
  ],
  ...(isTransparent
    ? [
        [
          { title: 'Invoice #', type: spendReportCellType.default },
          InvoiceNumberLink,
        ],
      ]
    : []),
  [
    { title: 'Invoice Date', type: spendReportCellType.default },
    R.compose(formatDate, R.prop('date')),
  ],
  ...(isTransparent
    ? [
        [
          { title: 'Supplier', type: spendReportCellType.default },
          R.propOr(NOTHING_UI_STRING, 'supplier'),
        ],
      ]
    : []),
  [
    { title: 'Service Type', type: spendReportCellType.default },
    R.propOr(NOTHING_UI_STRING, 'serviceType'),
  ],
  [
    { title: 'Description', type: spendReportCellType.default },
    R.propOr(NOTHING_UI_STRING, 'description'),
  ],
  [
    { title: 'Spend Type', type: spendReportCellType.default },
    getProjectSpendTypeOrNothingUI,
  ],
  [
    { title: 'Spend Cost', type: spendReportCellType.money },
    R.compose(formatTotal, getProjectSpendCostByConfig),
  ],
  ...(isTransparent
    ? [
        [
          { title: 'Cost', type: spendReportCellType.money },
          R.compose(formatTotal, convertCentsToDollars, R.propOr(0, 'total')),
        ],
      ]
    : []),
  [
    { title: 'Client Invoice', type: spendReportCellType.money },
    R.compose(
      formatTotal,
      convertCentsToDollars,
      R.propOr(0, 'clientInvoicesAmount'),
    ),
  ],
];

// prepareInvoicesReport :: [PropertyInvoicesReport] -> [PropertyInvoicesReport]
const prepareInvoicesReport = R.compose(
  R.unnest,
  R.juxt([
    R.converge(R.mergeRight, [
      R.compose(R.head, R.propOr([], 'invoices')),
      R.pick([
        'description',
        'projectNumber',
        'exemptSalesTax',
        'clientInvoicesAmount',
      ]),
    ]),
    R.compose(R.tail, R.propOr([], 'invoices')),
  ]),
  R.unless(
    R.propSatisfies(isNilOrEmpty, 'invoices'),
    assocBy(
      'invoices',
      R.converge(R.map, [
        R.compose(
          R.mergeLeft,
          R.applySpec({
            spendType: R.prop('spendType'),
            spendCostConfig: R.prop('spendCostConfig'),
          }),
        ),
        R.prop('invoices'),
      ]),
    ),
  ),
);

// prepareSpendReportTableData :: [PropertySpendReport] -> [PropertySpendReport]
const prepareSpendReportTableData = R.map(
  R.applySpec({
    clientInvoicesTotal: R.prop('clientInvoicesTotal'),
    invoicesTotal: R.prop('invoicesTotal'),
    propertyName: R.prop('propertyName'),
    propertyId: R.prop('propertyId'),
    propertyInvoicesReport: R.compose(
      R.unnest,
      R.map(prepareInvoicesReport),
      R.propOr([], 'propertyInvoicesReport'),
    ),
    childProperties: R.compose(
      R.map(
        R.unless(
          R.propSatisfies(isNilOrEmpty, 'propertyInvoicesReport'),
          R.over(
            R.lensProp('propertyInvoicesReport'),
            R.compose(R.unnest, R.map(prepareInvoicesReport)),
          ),
        ),
      ),
      R.propOr([], 'childProperties'),
    ),
  }),
);

export const useTableProps = (reports, isTransparent, isPrint) => {
  const tableConfig = getTableConfig(isTransparent, isPrint);

  return {
    rows: prepareSpendReportTableData(reports),
    columns: R.map(R.nth(1), tableConfig),
    headers: R.map(R.nth(0), tableConfig),
    gridColumns: isTransparent
      ? 'minmax(85px, 200px) repeat(2, minmax(70px, 200px)) repeat(2, minmax(100px, 200px)) minmax(200px, 1fr) repeat(4, minmax(100px, 200px))'
      : 'minmax(85px, 200px) minmax(70px, 200px) minmax(100px, 200px) minmax(200px, 1fr) repeat(3, minmax(100px, 200px))',
    isTransparent,
  };
};
