import { Tabs } from '@poly/site-book';
import React, { useState } from 'react';
import * as R from 'ramda';
import { Loader, WidgetBlock } from '@poly/site-ui';
import { arrayOf, bool, func, objectOf, shape, string } from 'prop-types';
import styled from 'styled-components';
import { useRouterQuery, useUpdateQueryParams } from '@poly/client-routing';

import { TabsSearchInput } from './TabsSearchInput.js';
import { tabsNames } from '../constants/tabs.js';
import { useClearSearchText } from '../redux/searchText.js';
import { Icon } from './Icon.js';

const TabsItemsContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const TabsItem = styled(Tabs.Items)`
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  height: 32px;
  width: 74px;
  border: 1px solid #f0f1f1;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  margin-left: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#F5F8FF' : '#FFF')};
  height: 100%;
  width: 100%;
  padding: 0 3px;
`;

const LeftIconWrapper = styled(IconWrapper)`
  border-radius: 10px 0 0 10px;
`;

const RightIconWrapper = styled(IconWrapper)`
  border-radius: 0 10px 10px 0;
`;

const Delimiter = styled.div`
  height: 20px;
  width: 1px;
  background-color: #f0f1f1;
`;

const showSearchInput = R.includes(R.__, [
  tabsNames.assets,
  tabsNames.projects,
  tabsNames.recurring,
  tabsNames.files,
  tabsNames.pms,
  tabsNames.assets,
]);

// showFilesTypeViewButtons :: (String, Boolean) -> Boolean
const showFilesTypeViewButtons = (tab, withBtn) =>
  R.both(R.includes(R.__, [tabsNames.files]), R.always(withBtn))(tab);

function FilesTypeViewButtons({ isGallery, setIsGallery }) {
  const toggleGallery = () => setIsGallery(!isGallery);
  return (
    <ButtonsWrapper>
      <LeftIconWrapper isActive={!isGallery} onClick={toggleGallery}>
        <Icon name="list" fill={isGallery ? '#94969C' : '#014398'} />
      </LeftIconWrapper>
      <Delimiter />
      <RightIconWrapper isActive={isGallery} onClick={toggleGallery}>
        <Icon name="gallery" fill={isGallery ? '#014398' : '#94969C'} />
      </RightIconWrapper>
    </ButtonsWrapper>
  );
}

FilesTypeViewButtons.propTypes = {
  isGallery: bool.isRequired,
  setIsGallery: func.isRequired,
};

export function TabsWithSearchSidebar({
  activeTabsMap,
  defaultTab,
  loading,
  tabs,
  changeViewBtn,
  ...props
}) {
  const tabQueryKey = 'tab';
  const queryByKey = useRouterQuery([tabQueryKey]);
  const preparedValue = queryByKey[tabQueryKey] || defaultTab;
  const updateQueryParams = useUpdateQueryParams();
  useClearSearchText();

  const [isGallery, setIsGallery] = useState(false);

  const ActiveTab = activeTabsMap[preparedValue] || activeTabsMap[defaultTab];

  const onClick = (e) =>
    updateQueryParams({ [tabQueryKey]: e.target.dataset.tab });

  return (
    <WidgetBlock>
      <Tabs>
        <TabsItem>
          <TabsItemsContainer>
            {tabs.map(({ value, title }) => (
              <Tabs.Item
                bold
                key={value}
                data-tab={value}
                active={value === preparedValue}
                onClick={onClick}
              >
                {title}
              </Tabs.Item>
            ))}
          </TabsItemsContainer>
          {showSearchInput(preparedValue) && (
            <TabsSearchInput withBorder={changeViewBtn} />
          )}
          {showFilesTypeViewButtons(preparedValue, changeViewBtn) && (
            <FilesTypeViewButtons
              isGallery={isGallery}
              setIsGallery={setIsGallery}
            />
          )}
        </TabsItem>
      </Tabs>
      {loading ? <Loader /> : <ActiveTab isGallery={isGallery} {...props} />}
    </WidgetBlock>
  );
}

TabsWithSearchSidebar.propTypes = {
  tabs: arrayOf(shape({ value: string.isRequired, title: string.isRequired })),
  loading: bool.isRequired,
  activeTabsMap: objectOf(func),
  defaultTab: string.isRequired,
  changeViewBtn: bool,
};
