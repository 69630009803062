import * as R from 'ramda';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { shape, string, instanceOf, func, bool, arrayOf } from 'prop-types';
import {
  CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  getSpendReportsXlsConfig,
  performExcelExport,
  showBase64PDF,
} from '@poly/client-utils';
import {
  getPropertiesDeepByHierarchyNodeR,
  isNilOrEmpty,
  formatDate,
  toDate,
} from '@poly/utils';
import {
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
  DEFAULT_PROPERTY_HIERARCHY_NAME,
  EXPORT_XLS_CAPTION,
} from '@poly/constants';
import {
  PrintAndExportButtons,
  DateRangePiker,
  ALERTS,
  A,
} from '@poly/site-ui';
import {
  HeadingH1,
  Checkbox,
  Layout,
  Header,
  Button,
  Icon,
  S,
} from '@poly/site-book';

import { Navigation } from '../Navigation.js';
import { PropertySelect } from './PropertySelect.js';
import { HierarchySelect } from './HierarchySelect.js';
import { spendReportHierarchyPropTypes } from './prop-types.js';
import { ProjectSpendTypeSelect } from './ProjectSpendTypeSelect.js';
import { useHierarchyOptions } from '../../hooks/useHierarchyOptions.js';
import { toMutationInput } from './helpers.js';

export const ButtonS = styled(Button)`
  margin-left: 35px;
`;

export const DateRangePikerS = styled(DateRangePiker)`
  > section > div {
    gap: 0;
    width: 120px;
  }
`;

const SpendCostConfigTitleS = styled.span`
  font-size: 12px;
  color: #6f7a9f;
  text-transform: uppercase;
  margin-right: 30px;
`;

const CheckboxS = styled(Checkbox)`
  color: #90939d;
  margin-right: 15px;
`;

export function ExportButtonComponent({ exportFunction }) {
  return <A onClick={exportFunction}>{EXPORT_XLS_CAPTION}</A>;
}

ExportButtonComponent.propTypes = {
  exportFunction: func,
};

// prepareExportSpendReport :: [SpendReport] -> [ClientPortalReportData]
const prepareExportSpendReport = R.compose(
  R.flatten,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

// viewSpendReportPdfP :: ClientSpendReportResult -> Promise _ _
const viewSpendReportPdfP = R.compose(
  showBase64PDF,
  R.path(['createClientSpendReportWithHierarchy', 'fileString']),
);

export function SpendReportHeader({
  filter,
  reports,
  clientId,
  setFilter,
  clientName,
  isTransparent,
  defaultEndDate,
  defaultStartDate,
  isExemptFromSalesTax,
  selectedPropertyOptions,
  isClientWithoutAnyHierarchy,
}) {
  const { options, loading } = useHierarchyOptions();
  const [createClientSpendReportWithHierarchyMutation] = useMutation(
    CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  );
  const [filterValues, setFilterValues] = useState({
    startDate: defaultStartDate
      ? toDate(defaultStartDate)
      : startOfDay(subMonths(new Date(), 1)),
    endDate: defaultStartDate ? toDate(defaultEndDate) : endOfDay(new Date()),
    hierarchyId: DEFAULT_PROPERTY_HIERARCHY_OPTION,
    spendCostConfig: {
      includeClientInvoice: false,
      includeSupplierInvoice: false,
    },
    ...selectedPropertyOptions,
  });

  const exportSpendReport = () => {
    if (isNilOrEmpty(reports)) {
      toast.error(ALERTS.NoDataToDisplay);
      return;
    }

    const config = getSpendReportsXlsConfig(
      prepareExportSpendReport(reports),
      clientName,
      formatDate(filter.startDate),
      formatDate(filter.endDate),
      isTransparent,
    );
    performExcelExport(config);
  };

  const printSpendReport = async () => {
    if (isNilOrEmpty(reports)) {
      toast.error(ALERTS.NoDataToDisplay);
      return;
    }

    const { data } = await createClientSpendReportWithHierarchyMutation({
      variables: {
        input: {
          ...toMutationInput(filter),
          clientId,
        },
      },
    });

    await viewSpendReportPdfP(data);
  };

  const onPreview = () =>
    setFilter({
      ...filterValues,
      spendCostConfig: {
        ...filterValues.spendCostConfig,
        ...(isTransparent ? {} : { includeClientInvoice: true }),
      },
    });

  const onChangeDateRange = ({ startDate, endDate }) =>
    setFilterValues({
      ...filterValues,
      startDate: startDate ? startOfDay(toDate(startDate)) : null,
      endDate: endDate ? endOfDay(toDate(endDate)) : null,
    });

  const onChangeProperty = (propertyId = []) => {
    setFilterValues({
      ...filterValues,
      propertyId,
    });
  };

  const onChangeSpendType = (spendType) => {
    setFilterValues({
      ...filterValues,
      spendType,
    });
  };

  const onChangeSpendCost = (name) => {
    setFilterValues({
      ...filterValues,
      spendCostConfig: {
        ...filterValues.spendCostConfig,
        [name]: !filterValues.spendCostConfig[name],
      },
    });
  };

  const onChangeHierarchy = (hierarchy) => {
    setFilterValues({
      ...filterValues,
      ...(hierarchy ? { hierarchyId: hierarchy.value } : {}),
    });
  };

  return (
    <Layout.Header>
      <Navigation />
      <Header.Sub>
        <Header.Row>
          <Header.Block>
            <HeadingH1 lighter>Spend Report</HeadingH1>
          </Header.Block>
          <Header.Block>
            <PrintAndExportButtons
              printTitle="Spend Report"
              exportFunction={exportSpendReport}
              printFunction={() => printSpendReport}
              ExportButtonComponent={ExportButtonComponent}
            />
          </Header.Block>
        </Header.Row>
        <Header.Row>
          <Header.Block>
            <DateRangePikerS
              startDate={filterValues.startDate}
              endDate={filterValues.endDate}
              onChange={onChangeDateRange}
            />
            <PropertySelect
              onChange={onChangeProperty}
              value={filterValues.propertyId}
            />
            <ProjectSpendTypeSelect
              onChange={onChangeSpendType}
              value={filterValues.spendType}
            />
            {!isClientWithoutAnyHierarchy && (
              <HierarchySelect
                value={filterValues.hierarchyId}
                onChange={onChangeHierarchy}
                isDisabled={loading}
                options={[
                  {
                    label: DEFAULT_PROPERTY_HIERARCHY_NAME,
                    value: DEFAULT_PROPERTY_HIERARCHY_OPTION,
                  },
                  ...options,
                ]}
              />
            )}
            <ButtonS onClick={onPreview}>
              <S type="title">Preview</S>
            </ButtonS>
          </Header.Block>
        </Header.Row>
        <Header.Row>
          {isTransparent && (
            <Header.Block>
              <SpendCostConfigTitleS>Spend Cost Config:</SpendCostConfigTitleS>
              <CheckboxS
                id="include-supplier-invoice"
                content="Include Supplier Invoice"
                onChange={() => onChangeSpendCost('includeSupplierInvoice')}
              />
              <CheckboxS
                id="include-client-invoice"
                content="Include Client Invoice"
                onChange={() => onChangeSpendCost('includeClientInvoice')}
              />
            </Header.Block>
          )}
          {isExemptFromSalesTax && (
            <Header.Block>
              <Icon name="warning" fill="#f79009" dimensions={{ height: 12 }} />
              <S textColor="#f79009" size={12} style={{ marginLeft: 8 }}>
                This client is exempt from sales tax
              </S>
            </Header.Block>
          )}
        </Header.Row>
      </Header.Sub>
    </Layout.Header>
  );
}

SpendReportHeader.propTypes = {
  clientId: string,
  clientName: string,
  isTransparent: bool,
  defaultEndDate: string,
  defaultStartDate: string,
  setFilter: func.isRequired,
  isExemptFromSalesTax: bool,
  isClientWithoutAnyHierarchy: bool,
  reports: arrayOf(shape(spendReportHierarchyPropTypes)),
  selectedPropertyOptions: shape({
    propertyId: arrayOf(
      shape({ value: string.isRequired, label: string.isRequired }),
    ),
  }),
  filter: shape({
    hierarchyId: string,
    endDate: instanceOf(Date),
    startDate: instanceOf(Date),
    propertyId: arrayOf(
      shape({ value: string.isRequired, label: string.isRequired }),
    ),
  }).isRequired,
};
