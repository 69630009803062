import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const ToastContainerS = styled(ToastContainer)`
  .Toastify__toast-container {
    width: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .Toastify__toast-container--top-right {
    top: 80px;
  }

  .Toastify__toast {
    font-size: 14px;
    min-height: 50px;
    min-width: 100px;
    max-width: 450px;
    font-weight: 500;
    border-radius: 3px;
    font-family: inherit;
    padding: 8px 35px 8px 16px;
  }

  .Toastify__toast--success {
    opacity: 0.9;
    color: #529801;
    background: #d8f1bc;
    border: solid 1px rgba(132, 188, 69, 0.31);
  }

  .Toastify__toast--error {
    opacity: 0.9;
    color: #ac0a0a;
    background: #f6baba;
    border: solid 1px rgba(181, 83, 83, 0.31);
  }

  .Toastify__close-button {
    right: 16px;
    display: flex;
    font-weight: 100;
    font-weight: 100;
    color: #3e4c5b;
    margin-left: 20px;
    align-self: center;
    top: calc(50% - 8px);
  }
`;

export function Toaster(props) {
  return <ToastContainerS {...props} newestOnTop hideProgressBar />;
}
