import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@poly/site-book';
import { useTableSearch, setCurrent } from '@poly/site-ui';
import { bool } from 'prop-types';

import { setSearchText } from '../redux/searchText.js';

const SearchInputC = styled.input`
  width: 180px;
  height: 20px;
  border: none;
  font-size: 12px;
  margin-left: 5px;
  padding-right: 20px;
  ::placeholder {
    color: #bcbcbc;
  }
`;

const searchInputBorderStyles = css`
  border: 1px solid #f0f1f1;
  border-radius: 6px;
  padding: 5px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${({ withBorder }) => (withBorder ? searchInputBorderStyles : '')}
`;

const ClearIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: ${({ withBorder }) => (withBorder ? '7px;' : '4px;')};
`;

export function TabsSearchInput({ withBorder }) {
  const { setRef, onClear, onChange, disabled, hideCloseIcon } = useTableSearch(
    {
      setTableSearchString: setSearchText,
      setPaginationCurrentPage: setCurrent,
    },
  );

  return (
    <SearchInputContainer withBorder={withBorder}>
      <Icon
        name="search"
        dimensions={{ width: 15, height: 15 }}
        fill="#BCBCBC"
      />
      <SearchInputC
        onChange={onChange}
        disabled={disabled}
        autoFocus
        ref={(ref) => setRef(ref)}
        placeholder="Search"
      />
      {!hideCloseIcon && (
        <ClearIconContainer withBorder={withBorder} onClick={onClear}>
          <Icon
            name="close"
            dimensions={{ width: 10, height: 10 }}
            fill="#9197AC"
          />
        </ClearIconContainer>
      )}
    </SearchInputContainer>
  );
}

TabsSearchInput.propTypes = {
  withBorder: bool,
};
