import { gql } from '@apollo/client';

export const projectsQuery = gql`
  query getProjects($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      total
      hits {
        _id
        description
        startDate
        endDate
        endDate
        workCompletionDate
        externalNumber
        projectId
        status
        priority {
          id
          name
        }
        property {
          _id
          name
        }
        technicians {
          _id
          fullName
        }
        manager {
          _id
          fullName
        }
        suppliers {
          _id
          company {
            name
          }
        }
        serviceType {
          _id
          name
        }
      }
    }
  }
`;

export const projectsChanged = gql`
  subscription projectsChanged($input: CollectionSearchParams!) {
    searchProjectChanged(input: $input) {
      id
      type
    }
  }
`;
