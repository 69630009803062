import React, { useState } from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { bool, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useEntityFiles } from '@poly/site-ui/src/components/FilesTab/useEntityFiles.js';
import { Loader } from '@poly/site-ui/src/components/Loader.js';
import { isImageFileType } from '@poly/client-utils/src/files.js';
import { getExtensionByFileName } from '@poly/utils/src/files.js';
import { ImagesGallery } from '@poly/site-ui/src/components/ImagesGallery/ImagesGallery.js';
import { setImagesGalleryModal } from '@poly/site-ui/src/redux/imagesGallery.js';

import { FileIcon } from './FileIcon.js';

const filesContainerGalleryStyles = css`
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const FilesContainer = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
  ${({ isGallery }) =>
    isGallery ? filesContainerGalleryStyles : 'flex-direction: column;'}
`;

const fileImageGalleryStyles = css`
  width: 122px;
  height: 122px;
`;

const FileImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: 25% 25%;
  border-radius: 4px;
  cursor: pointer;
  ${({ isGallery }) => (isGallery ? fileImageGalleryStyles : '')}
`;

const PreviewImage = styled(FileImage)`
  position: absolute;
  top: 0;
  left: 45px;
  z-index: 999999;
  cursor: none;
  width: 224px;
  height: 224px;
`;

const fileListItemContainerGalleryStyles = css`
  height: 170px;
  width: 122px;
  flex-direction: column;
`;

const FileListItemContainer = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  ${({ isGallery }) => (isGallery ? fileListItemContainerGalleryStyles : '')}
`;

const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const FileNameText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #333741;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSizeText = styled(FileNameText)`
  font-size: 14px;
  color: #61646c;
`;

// formatFileSize :: File -> String
const formatFileSize = R.compose(
  R.cond([
    [R.gte(R.__, 1_048_576), (bytes) => `${(bytes / 1_048_576).toFixed(2)} MB`],
    [R.gte(R.__, 1024), (bytes) => `${(bytes / 1024).toFixed(2)} KB`],
    [R.T, (bytes) => `${bytes} B`],
  ]),
  R.defaultTo(0),
  R.prop('fileSize'),
);

// getFileExtension :: File -> String
const getFileExtension = R.compose(getExtensionByFileName, R.prop('fileName'));

function FileListItem({ file, isGallery }) {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const isImage = isImageFileType(file);
  const iconSize = isGallery ? 122 : 40;
  const ext = getFileExtension(file);

  const onImageClick = () =>
    dispatch(
      setImagesGalleryModal({
        images: [file],
        currentIndex: 0,
      }),
    );

  return (
    <FileListItemContainer isGallery={isGallery}>
      {isImage ? (
        <>
          <FileImage
            isGallery={isGallery}
            src={file.url}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onImageClick}
          />
          {isHovered && !isGallery && <PreviewImage src={file.url} />}
        </>
      ) : (
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          <FileIcon name={ext} size={iconSize} />
        </a>
      )}

      <FileInfoContainer>
        <FileNameText>{file.fileName}</FileNameText>
        <FileSizeText>{formatFileSize(file)}</FileSizeText>
      </FileInfoContainer>
    </FileListItemContainer>
  );
}

FileListItem.propTypes = {
  file: shape({
    url: string,
    fileName: string,
  }),
  isGallery: bool,
};

export function FilesTab({ collection, documentId, isGallery }) {
  const { files, loading } = useEntityFiles({
    collection,
    documentId,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <FilesContainer isGallery={isGallery}>
        {files.map((file) => (
          <FileListItem key={file._id} file={file} isGallery={isGallery} />
        ))}
      </FilesContainer>
      <ImagesGallery />
    </>
  );
}

FilesTab.propTypes = {
  collection: string.isRequired,
  documentId: string.isRequired,
  isGallery: bool,
};
