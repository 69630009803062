import * as R from 'ramda';
import React, { useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { getDateByPropWithTime, pathOrNothingUI } from '@poly/client-utils';
import { L } from '@poly/site-book';
import {
  getProjectStatusTextNoQueue,
  getProjectStatusColor,
} from '@poly/site-ui';
import {
  centsToDollarsWithFormat,
  calculateTotal,
  formatDate,
  propEqLegacy,
  pathEqLegacy,
} from '@poly/utils';
import {
  ClientSpendReportTypes,
  ProjectSupplierStatus,
  ClientInvoiceStatuses,
  ProjectTypeToNameMap,
  WorkOrderPriority,
  NOTHING_UI_STRING,
  ProjectType,
} from '@poly/constants';

import { PropertyLink } from '../../components/Links.js';
import { ClientInvoicesModal } from './ClientInvoicesModal.js';
import { DetailsRow, DetailsSection } from './DetailsSection.js';
import { SupplierInvoicesModal } from './SupplierInvoicesModal.js';
import { SubLinksComponent } from '../../components/SubLinksComponent.js';

// formatProjectDetailsObject :: Project ->Object
const formatProjectDetailsObject = R.applySpec({
  projectId: R.prop('_id'),
  serviceName: R.path(['serviceType', 'name']),
  property: R.prop('property'),
  description: R.prop('description'),
  priority: R.prop('priority'),
  startDate: getDateByPropWithTime('startDate'),
  endDate: getDateByPropWithTime('endDate'),
  workCompletionDate: R.compose(
    R.defaultTo(NOTHING_UI_STRING),
    formatDate,
    R.prop('workCompletionDate'),
  ),
  aacManagerName: pathOrNothingUI(['manager', 'fullName']),
  requestorName: pathOrNothingUI(['contact', 'fullName']),
  status: getProjectStatusTextNoQueue,
  supplierName: R.compose(
    R.defaultTo('-'),
    R.join(' , '),
    R.map(R.path(['company', 'name'])),
    R.filter(propEqLegacy('statusInProject', ProjectSupplierStatus.assigned)),
    R.propOr([], 'suppliers'),
  ),
  jobCost: R.propOr(0, 'jobCost'),
  invoices: R.propOr([], 'clientInvoices'),
  invoicesTotal: R.compose(
    calculateTotal(
      R.ifElse(
        propEqLegacy('status', ClientInvoiceStatuses.VOIDED),
        R.always(0),
        R.prop('amount'),
      ),
    ),
    R.propOr([], 'clientInvoices'),
  ),
  subProperties: R.pathOr([], ['subProperties']),
});

// isTransparent :: Project -> Boolean
const isTransparent = pathEqLegacy(
  ['client', 'financial', 'spendReportType'],
  ClientSpendReportTypes.TRANSPARENT,
);

const yellowColor = '#ff8c00';

function InvoicesTotalLink({ total, isClickable, onClick }) {
  const handleClick = () => {
    if (isClickable) {
      onClick();
    }
  };
  return <L onClick={handleClick}>{centsToDollarsWithFormat(total)}</L>;
}

InvoicesTotalLink.propTypes = {
  total: number.isRequired,
  onClick: func.isRequired,
  isClickable: bool.isRequired,
};

export function ProjectDetailsSection({ project }) {
  const [showSupplierInvoices, setShowSupplierInvoices] = useState(false);
  const [showClientInvoices, setShowClientInvoices] = useState(false);

  const {
    status,
    endDate,
    jobCost,
    property,
    priority,
    invoices,
    projectId,
    startDate,
    serviceName,
    description,
    supplierName,
    subProperties,
    requestorName,
    invoicesTotal,
    aacManagerName,
    workCompletionDate,
  } = formatProjectDetailsObject(project);

  return (
    <DetailsSection
      title="Work Order Detail"
      status={{
        text: status,
        color: getProjectStatusColor(project),
      }}
    >
      <DetailsRow
        title="Priority"
        content={priority?.name}
        icon={
          priority?.id === WorkOrderPriority.URGENT
            ? { name: 'clock', dimensions: { width: 16, height: 16 } }
            : null
        }
      />
      <DetailsRow
        title="Type"
        content={serviceName || ProjectTypeToNameMap[ProjectType.FEE]}
        color={yellowColor}
      />
      <DetailsRow title="Description" content={description} />
      <DetailsRow
        title={`${property?.isMaster ? 'Master ' : ''}Property`}
        content={property && <PropertyLink {...property} />}
      />
      {subProperties.length > 0 && (
        <DetailsRow
          title="Sub Properties"
          content={
            <SubLinksComponent
              subLinks={subProperties}
              LinkComponent={PropertyLink}
            />
          }
        />
      )}
      <DetailsRow title="Start Date" content={startDate} />
      <DetailsRow title="End Date" content={endDate} />
      <DetailsRow title="Completion Date" content={workCompletionDate} />
      <DetailsRow title="Assigned CSR" content={aacManagerName} />
      <DetailsRow title="Requestor" content={requestorName} />
      {isTransparent(project) && (
        <>
          <DetailsRow title="Supplier" content={supplierName} />
          <DetailsRow
            title="Supplier Invoices"
            content={
              <InvoicesTotalLink
                total={jobCost}
                isClickable={jobCost !== 0}
                onClick={() => setShowSupplierInvoices(true)}
              />
            }
          />
        </>
      )}
      <DetailsRow
        title="Work Order Invoices"
        content={
          <InvoicesTotalLink
            total={invoicesTotal}
            isClickable={invoices.length > 0}
            onClick={() => setShowClientInvoices(true)}
          />
        }
      />
      <SupplierInvoicesModal
        projectId={projectId}
        show={showSupplierInvoices}
        closeModal={() => setShowSupplierInvoices(false)}
      />
      <ClientInvoicesModal
        show={showClientInvoices}
        closeModal={() => setShowClientInvoices(false)}
        invoices={invoices}
      />
    </DetailsSection>
  );
}

ProjectDetailsSection.propTypes = {
  project: shape({
    projectId: string.isRequired,
  }),
};
