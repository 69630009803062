import * as R from 'ramda';
import { gql } from '@apollo/client';

import { DESC_SORT_ORDER } from '@poly/constants';
import { useReactiveQuery } from '@poly/client-utils';
import { useSelector } from 'react-redux';

const filesQuery = gql`
  query getFilesByCollection($input: CollectionSearchParams!) {
    searchFiles(input: $input) {
      hits {
        _id
        fileType
        fileName
        createdAt
        fileSize
        url
        createdBy {
          _id
        }
      }
      total
    }
  }
`;

const filesSubscription = gql`
  subscription filesChanged($input: CollectionSearchParams!) {
    searchFileChanged(input: $input) {
      id
      type
    }
  }
`;

export const useEntityFiles = ({
  collection,
  documentId,
  sort = { createdAt: DESC_SORT_ORDER },
  size = 1000,
  from = 0,
}) => {
  const searchText = useSelector(R.prop('searchText'));
  const input = {
    sort,
    size,
    from,
    searchTerm: searchText,
    query: {
      nested: {
        path: 'relations',
        query: {
          bool: {
            must: [
              ...(collection
                ? [{ match: { 'relations.collection': collection } }]
                : []),
              ...(documentId
                ? [{ match: { 'relations._id': documentId } }]
                : []),
            ],
          },
        },
      },
    },
  };

  const queryOptions = {
    variables: { input },
    skip: !collection && !documentId,
  };

  const { data, loading, debouncedRefetch } = useReactiveQuery(
    filesQuery,
    filesSubscription,
    {
      queryOptions,
      subscriptionOptions: queryOptions,
    },
  );
  return {
    files: R.pathOr([], ['searchFiles', 'hits'], data),
    total: R.pathOr([], ['searchFiles', 'total'], data),
    loading,
    debouncedRefetch,
  };
};
