import React, { useEffect } from 'react';
import * as R from 'ramda';
import { SelectBase } from '@poly/site-ui';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import {
  ASC_SORT_ORDER,
  ClientStatuses,
  ELASTIC_RESULT_WINDOW_MAX_SIZE,
} from '@poly/constants';
import { useDispatch, useSelector } from 'react-redux';
import { CLIENT_PORTAL_APP_NAME } from '@poly/security/src/app-names.js';
import {
  useRouterQuery,
  useUpdateQueryParams,
} from '@poly/client-routing/src/hooks.js';
import { keywordSortQuery } from '@poly/client-utils/src/sorting.js';
import { propEqLegacy } from '@poly/utils';

import { setCurrentClient } from '../redux/currentClient.js';

const SelectBaseS = styled(SelectBase)`
  margin-right: 10px;
  width: 250px;
`;

export const CLIENTS_SEARCH_QUERY = gql`
  query CLIENTS_SEARCH_FILTER($searchInput: CollectionSearchParams!) {
    searchClients(input: $searchInput) {
      hits {
        _id
        name
        configs {
          clientPortalApp {
            showPMsAndAssets
            allowProjectCreation
            projectServiceType {
              _id
            }
          }
          projectPriorities {
            id
            name
            amount
            unit
          }
        }
        financial {
          spendReportType
          exemptSalesTax
        }
      }
      total
    }
  }
`;

// getClientSelectOption :: Client -> Option
const getClientSelectOption = R.applySpec({
  label: R.prop('name'),
  value: R.identity,
});

// getClientSelectOptions :: SearchClients -> [Option]
const getClientSelectOptions = R.compose(
  R.map(getClientSelectOption),
  R.pathOr([], ['searchClients', 'hits']),
);

// getDefaultClient ::  (ID, SearchClients) -> Option
const getDefaultClient = (id, data) =>
  R.compose(
    getClientSelectOption,
    R.either(R.head, R.last),
    R.juxt([R.find(propEqLegacy('_id', id)), R.head]),
    R.pathOr([], ['searchClients', 'hits']),
  )(data);

// getSelectedClientId :: Option -> ID
const getSelectedClientId = R.path(['value', '_id']);

export const CURRENT_CLIENT_SELECTED_ID_KEY = 'CURRENT_CLIENT_SELECTED_ID_KEY';

export const searchClientsQueryInput = {
  size: ELASTIC_RESULT_WINDOW_MAX_SIZE,
  query: {
    bool: {
      must: [
        { match: { status: ClientStatuses.ACTIVE } },
        { match: { apps: CLIENT_PORTAL_APP_NAME } },
      ],
    },
  },
  sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
};

export function CurrentGlobalClientSelect() {
  const client = useSelector((state) => state.currentClient);

  const updateQueryParams = useUpdateQueryParams();

  const { currentClient } = useRouterQuery(['currentClient']);

  const currentClientId = localStorage.getItem(CURRENT_CLIENT_SELECTED_ID_KEY);

  const { data, loading } = useQuery(CLIENTS_SEARCH_QUERY, {
    variables: {
      searchInput: searchClientsQueryInput,
    },
  });

  const isSingleClient = !loading && data?.searchClients?.total === 1;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!client && !loading) {
      dispatch(
        setCurrentClient(
          getDefaultClient(currentClient || currentClientId, data),
        ),
      );
    }
  }, [data]);

  useEffect(() => {
    if (client && !isSingleClient) {
      updateQueryParams({ currentClient: getSelectedClientId(client) });
    }
  }, [client, isSingleClient]);

  if (!data || isSingleClient) {
    return null;
  }

  const onClientChange = (value) => {
    dispatch(setCurrentClient(value));
    localStorage.setItem(
      CURRENT_CLIENT_SELECTED_ID_KEY,
      getSelectedClientId(value),
    );
  };

  const selectProps = {
    options: getClientSelectOptions(data),
    placeholder: 'Start Typing Client',
    value: client,
    onChange: onClientChange,
    isLoading: loading,
    clearable: false,
  };
  return <SelectBaseS {...selectProps} />;
}
